import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./Main";
import socket from "../socket";
import { link } from "./settings";
import "./../styles/cfe.css";
function App() {
  const session = localStorage.getItem("sessionID");
  const sessionID = JSON.parse(session);

  React.useEffect(() => {
    if (sessionID) {
      socket.auth = { sessionID: sessionID };
      socket.connect();
      window.location.href = link;
    }
  }, [sessionID]);

  React.useEffect(() => {
    socket.on("session", (e) => {
      if (e.is !== undefined) {
        socket.auth = { sessionID: e.is };
        localStorage.setItem("sessionID", JSON.stringify(e.is));
      }
    });
    return () => {
      socket.off("session");
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
