import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    storeMethod: (state, action) => {
      const props = action.payload;
      props.name = "";
      state.push(props[0]);
    },
  },
});

// Action creators are generated for each case reducer function
export const { storeMethod } = usersSlice.actions;
export default usersSlice.reducer;
