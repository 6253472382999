import { Manager } from "socket.io-client";

const URL = "https://s00.onrender.com";
const manager = new Manager(URL, {
  autoConnect: false,
  withCredentials: true,
  extraHeaders: {
    "header-4f798cb3-02dd-40ad-a60d-999a66200094":
      "abcd-30a4474b-8d34-4b7f-a0ce-b939c07cba7e",
  },
});
const socket = manager.socket("/cfeClient");

export default socket;

