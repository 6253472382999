import React from "react";
import { GridLoader } from "react-spinners";

function Replays({ a, time }) {
  const aux = 60;
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  return (
    <>
      <div
        id="WRAPPER_TXT_961AAABC0AD2BBFC49952"
        style={{
          display: `${a ? "block" : "none"}`,
          position: "fixed",
          zIndex: "9999",
          paddingTop: "50px",
          left: "0px",
          top: "0px",
          width: "100%",
          height: "100%",
          overflow: "auto",
          backgroundColor: "rgba(255,255,255,0.5)",
        }}
      >
        <div
          id="CONTENT_TXT_961AAABC0AD2BBFC49952"
          className="tc-global-font"
          style={{
            position: "fixed",
            width: "140px",
            height: "100px",
            top: "50%",
            left: "50%",
            zIndex: "9999",
            lineHeight: "30px",
            marginLeft: "-70px",
            marginTop: "-50px",
            textAlign: "center",
            opacity: "0.85",
            color: "#008E5A",
            borderRadius: "4px",
            paddingTop: "7px",
          }}
        >
          <div
            style={{
              top: "100px",
              position: "absolute",
              bottom: "0px",
              textAlign: "center",
              width: "100%",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {time > 15 && 30 >= time && <div>Conectando...</div>}

            {time > 30 && <div>{time > 30 ? aux - time : ""} Cargando...</div>}
          </div>
          <GridLoader
            color="#008E5A"
            size="15"
            margin="2px"
            cssOverride={override}
            loading="true"
            speedMultiplier="1"
            width="10"
          />
        </div>
      </div>
    </>
  );
}

export default Replays;
